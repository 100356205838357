import React, { Fragment, useEffect, useState, useRef } from "react"
import { SiteClient } from "datocms-client"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { StructuredText } from "react-datocms"

import Seo from "../components/SEO"
import Footer from "../components/Footer"
import { DonationBox, LayoutContainer } from "../components/UI"
import ImpactFundHeader from "../components/ImpactFundHeader"
import ImpactFundDonationBox from "../components/ImpactFundDonationBox"
import ImpactFundCharitiesBox from "../components/ImpactFundCharitiesBox"
import ImpactFundAboutFund from "../components/ImpactFundAboutFund/ImpactFundAboutFund"
import DonationTotal from "../components/DonationTotal/DonationTotal"
import { capitalizeWord } from "../utils"

const client = new SiteClient("bbfa56bac3866b5d839373f3d6ca42")

const ImpactFundIntroTemplate = ({ data: { datoCmsImpactFund }, location }) => {
  const [raisedSoFar, setRaisedSoFar] = useState(0)
  const [total, setTotal] = useState(0)
  const childRef = useRef()
  const [step, setStep] = useState(1) // Change to 1
  const [data, setData] = useState({
    donation: 0,
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    postcode: "",
    giftAid: true,
    fee: datoCmsImpactFund.turnOffFee,
    // fee: true,
    giftAidPercentage: 25,
    feePercentage: datoCmsImpactFund.feePercentage || 0,
    marketingEmails: false,
  })
  const [manualPrice, setManualPrice] = useState(0)

  const dataInputHandler = e => {
    if (manualPrice !== null) {
      setManualPrice(0)
    }
    if (e.target.name === "donation") {
      setData({ ...data, [e.target.name]: parseFloat(e.target.value) })
      return setManualPrice(parseFloat(e.target.value))
    }
    if (e.target.type === "checkbox") {
      return setData({ ...data, [e.target.name]: e.target.checked })
    }
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const manualPriceInputHandler = e => {
    setManualPrice(parseFloat(e.target.value))
    setData({ ...data, donation: parseFloat(e.target.value) })
  }

  const giftAidHandler = bool => {
    setData({ ...data, giftAid: bool })
  }

  const nextStepHandler = () => {
    if (step <= 4) {
      // 4 Defines Number of Total Steps
      return setStep(step + 1)
    }
  }

  const previousStepHandler = () => {
    setStep(step - 1)
  }

  const stripeCheckoutHandler = () => {
    // console.log("Stripe Data is ", data)
  }

  const canonicalUrl = `https://thetoucan.app${location.pathname}`

  const resetForm = () => {
    setData({
      donation: 0,
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      postcode: "",
      giftAid: true,
      fee: datoCmsImpactFund.turnOffFee,
      // fee: true,
      giftAidPercentage: 25,
      feePercentage: datoCmsImpactFund.feePercentage || 0,
      marketingEmails: false,
    })
    setStep(1)
  }

  // Get Single Impact Fund By Slug

  const getSingleImpactFund = async () => {
    const records = await client.items.all({
      filter: {
        type: datoCmsImpactFund.model.originalId, // Model ID for Impact Funds / SLF
        fields: {
          id: {
            eq: datoCmsImpactFund.originalId,
          },
        },
      },
    })
    const record = records[0]
    const raisedAmount = record.amountCollected || 0
    const totalAmount = record.targetAmount || 0
    console.log("Raised ", raisedAmount)
    console.log("Total ", totalAmount)
    setTotal(totalAmount)
    setRaisedSoFar(raisedAmount)
  }

  const updateRaised = () => {
    if (!datoCmsImpactFund.showTargetTotal) return
    childRef?.current?.updateRaisedValue(raisedSoFar + data.donation)
  }

  const doubleCallImpactHandler = () => {
    getSingleImpactFund()
    updateRaised()
  }

  useEffect(() => {
    getSingleImpactFund()
  }, [])


  return (
    <Fragment>
      <Seo
        title={
          datoCmsImpactFund.seo?.title ||
          capitalizeWord(datoCmsImpactFund.name) ||
          capitalizeWord(datoCmsImpactFund.title)
        }
        description={datoCmsImpactFund.seo?.description}
        noIndex={datoCmsImpactFund.noIndex}
        canonicalUrl={canonicalUrl}
        imageUrl={datoCmsImpactFund?.heroImage?.url}
      />
      <ImpactFundHeader />
      <main className="pb-[140px] font-spartan">
        <div className="relative h-72 w-full bg-[#b3bcbd]">
          {datoCmsImpactFund.heroImage && (
            <GatsbyImage
              image={datoCmsImpactFund.heroImage.gatsbyImageData}
              alt={datoCmsImpactFund.heroImage.alt}
              className="h-72 w-full"
            />
          )}
          <span className="charity-hero-gradient absolute inset-0" />
        </div>
        <div className="relative z-10 -mt-24 md:mt-[-9rem]">
          <LayoutContainer>
            <h1 className="absolute -top-8 left-1/2 mx-auto mb-4 w-full -translate-x-1/2 text-center text-2xl font-bold text-white md:static md:left-0 md:translate-x-0 lg:text-left lg:text-5xl">
              {datoCmsImpactFund.title || datoCmsImpactFund.name}
            </h1>
          </LayoutContainer>
          <div className="mx-auto max-w-screen-2xl justify-betweeeeen lg:flex">
            <div className="order-2 px-6">
              <div className="relative top-10 lg:sticky">
                <ImpactFundDonationBox
                  step={step}
                  data={data}
                  dataInputHandler={dataInputHandler}
                  giftAidHandler={giftAidHandler}
                  manualPrice={manualPrice}
                  manualPriceInputHandler={manualPriceInputHandler}
                  nextStepHandler={nextStepHandler}
                  previousStepHandler={previousStepHandler}
                  resetForm={resetForm}
                  stripeCheckoutHandler={stripeCheckoutHandler}
                  datoCmsImpactFund={datoCmsImpactFund}
                  getSingleImpactFund={doubleCallImpactHandler}
                  raisedSoFar={raisedSoFar}
                />
                <DonationBox className="mt-6 mb-[56px] font-spartan text-[14px] leading-[22px] lg:mx-auto lg:max-w-md">
                  <p className="text-center font-spartan font-bold">
                    {datoCmsImpactFund.downloadSectionTitle ||
                      "Set up a monthly donation"}
                  </p>
                  <div className="monthly-donation-text mb-6 block text-center text-xs text-gray-2">
                    {datoCmsImpactFund.monthlyDonationText.value ? (
                      <StructuredText
                        data={datoCmsImpactFund.monthlyDonationText}
                      />
                    ) : (
                      <p className="mt-1 text-xs text-gray-2">
                        Availabe on Toucan
                      </p>
                    )}
                  </div>
                  <div className="flex items-center justify-between">
                    <a
                      href={datoCmsImpactFund.downloadNowLink}
                      className="font-bold"
                    >
                      Download now
                    </a>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                        d="M5.293 12.293L6.707 13.707L13.414 6.99997L6.707 0.292969L5.293 1.70697L9.586 5.99997H0V7.99997H9.586L5.293 12.293Z"
                        fill="#333333"
                      />
                    </svg>
                  </div>
                </DonationBox>
                {datoCmsImpactFund.showTargetTotal && (
                  <DonationTotal
                    ref={childRef}
                    raisedSoFar={raisedSoFar}
                    total={total}
                  />
                )}
                <ImpactFundCharitiesBox datoCmsImpactFund={datoCmsImpactFund} />
              </div>
            </div>
            <ImpactFundAboutFund
              datoCmsImpactFund={datoCmsImpactFund}
              className="relative order-1 md:mt-[8rem]"
            />
          </div>
        </div>
      </main>
      <Footer isImpactFundPage />
    </Fragment>
  )
}

export const query = graphql`
  query GetImpactFunds($slug: String!) {
    datoCmsImpactFund(slug: { eq: $slug }) {
      id
      name
      originalId
      title
      showTargetTotal
      iterableCampaignId
      targetAmount
      model {
        originalId
      }
      heroImage {
        gatsbyImageData
        alt
        url
      }
      meta {
        createdAt(formatString: "DD/MM/YYYY")
      }
      partner {
        meta {
          createdAt(formatString: "DD/MM/YYYY")
        }
        name
        avatar {
          gatsbyImageData(imgixParams: { w: "44", h: "44" })
          alt
        }
      }
      didyouknowtext
      didyouknow {
        didyouknowcard {
          blocks
          links
          value
        }
      }
      description {
        value
      }
      giftAidText {
        value
      }
      downloadSectionTitle
      monthlyDonationText {
        value
      }
      charities {
        name
        slug
        emailCharityName
        ctReference
        heroBackground {
          gatsbyImageData(imgixParams: { w: "104", h: "120" })
          alt
        }
        logo {
          gatsbyImageData(imgixParams: { w: "56", h: "56" })
          alt
        }
      }
      charityVideo {
        charityName
        videoTitle
        videoLink
        videoThumbnail {
          gatsbyImageData(imgixParams: { w: "220", h: "130" })
          alt
        }
      }
      feePercentage
      turnOffFee
      paymentDetailsText {
        value
      }
      appstoreLink
      playstoreLink
      downloadNowLink
      downloadLinkUnderCharities
      seo {
        title
        description
      }
      noIndex
    }
  }
`

export default ImpactFundIntroTemplate
