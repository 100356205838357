import React, { useState, useRef } from "react"
import { renderRule, StructuredText } from "react-datocms"
import { isParagraph } from "datocms-structured-text-utils"
import { useQueryParam, StringParam } from "use-query-params"
import { SiteClient } from "datocms-client"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"

import { DonationBox, DBButton, PaymentError } from "../UI"

const client = new SiteClient("bbfa56bac3866b5d839373f3d6ca42")

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#bdbdbd",
      color: "#bdbdbd",
      fontWeight: 700,
      fontFamily: "'Spartan', sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#bdbdbd",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0")
}

function formatDate(date) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/")
}

const dateToday = formatDate(new Date())

const PaymentForm = ({
  data,
  dataInputHandler,
  previousStepHandler,
  resetForm,
  datoCmsImpactFund,
  getSingleImpactFund,
  raisedSoFar,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  // const [paymentRequest, setPaymentRequest] = useState(null)
  const [error, setError] = useState(null)
  const [stripeInputError, setStripeInputError] = useState(null)
  const [isStripeIframeLoaded, setIsStripeIframeLoaded] = useState(false)
  const [success, setSuccess] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [utmSource] = useQueryParam("utm_source", StringParam)
  const [utmMedium] = useQueryParam("utm_medium", StringParam)
  const [utm_campaign] = useQueryParam("utm_campaign", StringParam)
  const frameRef = useRef()

  const fee = (data.donation / 100) * data.feePercentage
  const total = parseFloat(data.donation) + fee

  const donationAmount = data.giftAid
    ? data.donation + (data.donation / 100) * data.giftAidPercentage
    : data.donation

  const stripeCheckout = async event => {
    event.preventDefault()
    if (processing) return
    if (!isStripeIframeLoaded) return
    if (stripeInputError) return
    if (!stripe) return
    setProcessing(true)
    const request = await fetch("/.netlify/functions/server", {
      method: "post",
      body: JSON.stringify({
        paymentMethodType: "card",
        amount: data.fee ? parseFloat(total) : parseFloat(data.donation),
        currency: "gbp",
        metadata: {
          spotlight_name: datoCmsImpactFund.name,
          first_name: data.firstName,
          last_name: data.lastName,
          address: data.address,
          postcode: data.postcode,
          email: data.email,
          amount: data.donation,
          // gift_aid_amount: data.giftAid
          //   ? (data.donation / 100) * data.giftAidPercentage
          //   : 0,
          fee_amount: data.fee
            ? parseFloat((data.donation / 100) * data.feePercentage).toFixed(2)
            : 0,
          gift_aid: data.giftAid,
          fee: data.fee,
          marketing_emails: data.marketingEmails,
          disbursed: false,
          utm_source: utmSource || "",
          utm_medium: utmMedium || "",
          utm_campaign: utm_campaign || "",
        },
      }),
    })
    const r = await request.json()
    const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(r.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${data.firstName} ${data.lastName}`,
          },
        },
      })

    if (stripeError) {
      if (stripeError.code === "incomplete_number") {
        setStripeInputError(stripeError)
      } else {
        createRecordToDatoCMS("NAN", true, stripeError.message)
        setError(stripeError.message)
      }
    }
    if (paymentIntent) {
      createRecordToDatoCMS(paymentIntent.id, false, "")
      sendIterableEmail(paymentIntent.id)
      updateImpactFundCollectedAmount(datoCmsImpactFund.originalId)
      setSuccess(true)
      getSingleImpactFund()
    }
    setProcessing(false)
  }

  function addZeroes(num) {
    // Cast as number
    num = Number(num)
    // If not a number, return 0
    if (isNaN(num)) {
      return 0
    }
    // If there is no decimal, or the decimal is less than 2 digits, toFixed
    if (
      String(num).split(".").length < 2 ||
      String(num).split(".")[1].length <= 2
    ) {
      num = num.toFixed(2)
    }
    // Return the number
    return num
  }

  const sendIterableEmail = async id => {
    const g = data.giftAid
      ? data.donation + (data.donation / 100) * data.giftAidPercentage
      : data.donation
    const giftAid = addZeroes(g)
    await fetch("/.netlify/functions/iterable", {
      method: "post",
      body: JSON.stringify({
        email: data.email,
        fundName: datoCmsImpactFund.title || datoCmsImpactFund.name,
        firstName: data.firstName,
        lastName: data.lastName,
        donationAmount: addZeroes(data.donation),
        giftAid: giftAid,
        totalCharged: data.fee ? addZeroes(total) : addZeroes(data.donation),
        donationDate: dateToday,
        transactionID: id,
        charities: datoCmsImpactFund.charities,
        iterableCampaignId: +datoCmsImpactFund.iterableCampaignId
      }),
    })
  }

  const createRecordToDatoCMS = async (paymentId, status, failedMessage) => {
    await client.items.create({
      itemType: "1886028", // model ID
      unique_id_for_total: datoCmsImpactFund.id,
      spotlight_name: datoCmsImpactFund.name,
      first_name: data.firstName,
      last_name: data.lastName,
      address: data.address,
      postcode: data.postcode,
      email: data.email,
      transaction_id: paymentId,
      amount: data.donation,
      // gift_aid_amount: data.giftAid
      //   ? (data.donation / 100) * data.giftAidPercentage
      //   : 0,
      fee_amount: data.fee
        ? parseFloat((data.donation / 100) * data.feePercentage).toFixed(2)
        : 0,
      gift_aid: data.giftAid,
      fee: data.fee,
      marketing_emails: data.marketingEmails,
      disbursed: false,
      failed: status,
      utm_source: utmSource || "",
      utm_medium: utmMedium || "",
      utm_campaign: utm_campaign || "",
      failed_message: failedMessage,
      email_charity_name1: datoCmsImpactFund.charities[0]?.emailCharityName,
      ct_reference1: datoCmsImpactFund.charities[0]?.ctReference,
      email_charity_name2: datoCmsImpactFund.charities[1]?.emailCharityName,
      ct_reference2: datoCmsImpactFund.charities[1]?.ctReference,
      email_charity_name3: datoCmsImpactFund.charities[2]?.emailCharityName,
      ct_reference3: datoCmsImpactFund.charities[2]?.ctReference,
    })
  }

  const updateImpactFundCollectedAmount = async itemId => {
    const records = await client.items.all({
      filter: {
        type: datoCmsImpactFund.model.originalId, // Model ID for Impact Funds / SLF
        fields: {
          id: {
            eq: datoCmsImpactFund.originalId,
          },
        },
      },
    })
    const record = records[0]
    const raisedAmount = record.amountCollected || 0
    // if (!raisedAmount) return
    await client.items.update(itemId, {
      amount_collected: raisedAmount + data.donation,
    })
  }

  return success ? (
    <DonationBox className="lg:mx-auto lg:max-w-md">
      <p className="mb-6 text-center text-[14px] font-bold text-primary">
        Download the app
      </p>
      <p className="mb-[18px] text-center text-[12px] font-bold leading-[19.50px]">
        Thanks for your donation. <br />A receipt has been sent to <br />{" "}
        <span className="mt-[2px] inline-block text-[16px] font-light leading-[19.50px]">
          {data.email}
        </span>
      </p>
      <p className="mb-4 text-center text-[12px] font-bold leading-[19.50px]">
        Explore thousands more charities on the Toucan app, available now
      </p>
      <div className="flex items-center justify-between md:justify-center">
        <a
          className="inline-block md:mb-0 md:mr-6"
          itemProp="url"
          href={datoCmsImpactFund.appstoreLink}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://www.datocms-assets.com/54391/1630472284-download-btn-inline.svg"
            alt="Download iOS App from Apple Store"
            itemProp="image"
            className="max-w-[120px]"
          />
        </a>

        <a
          itemProp="url"
          href={datoCmsImpactFund.playstoreLink}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://www.datocms-assets.com/54391/1630472281-downloadandroid-inline.svg"
            alt="Download Android App from Google Play Store"
            itemProp="image"
            className="max-w-[120px]"
          />
        </a>
      </div>
    </DonationBox>
  ) : error ? (
    <DonationBox className="lg:mx-auto lg:max-w-md">
      <p className="mb-6 text-center text-[14px] font-bold text-primary">
        Something went wrong
      </p>
      <p className="mb-10 text-center text-[12px] font-bold leading-[19.50px]">
        Your donation was not successful and you have not been charged
      </p>
      <div className="flex justify-center">
        <DBButton
          title="Back to payment details"
          className="justify-center"
          disableSvg
          type="button"
          onClick={() => setError(null)}
        />
      </div>
    </DonationBox>
  ) : (
    <form onSubmit={stripeCheckout}>
      <DonationBox className="mb-14 lg:mx-auto lg:max-w-md">
        <button onClick={previousStepHandler}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
          >
            <path
              d="M29 22H15"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M22 29L15 22L22 15"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <h2 className="mb-2 text-center font-spartan font-bold text-gray-1">
          Payment details
        </h2>
        <div className="monthly-donation-text mb-6 block text-center">
          {datoCmsImpactFund.paymentDetailsText.value ? (
            <StructuredText
              data={datoCmsImpactFund.paymentDetailsText}
              customRules={[
                renderRule(isParagraph, ({ children, key }) => {
                  if (children[0].length === 0) return <br />
                  return (
                    <p
                      key={key}
                      className="mb-2 text-center font-spartan text-[12px] font-normal leading-[22.70px] text-gray-3"
                    >
                      {children}
                    </p>
                  )
                }),
              ]}
            />
          ) : (
            <p className="mb-2 text-center font-spartan text-[12px] font-normal leading-[22.70px] text-gray-3">
              Charities receive 100% of your donation through Toucan.
            </p>
          )}
        </div>
        <div className="mb-2 text-center text-[14px] leading-[23px] text-primary">
          <p>
            Donation amount: £{addZeroes(donationAmount)}{" "}
            {data.giftAid ? "(incl. Gift Aid)" : ""}
          </p>
          <p>
            Amount to pay: £
            {data.fee ? addZeroes(total) : addZeroes(data.donation)}
          </p>
        </div>
        <div className="mb-10 flex items-center justify-center">
          {/* {!datoCmsImpactFund.turnOffFee && (
            <>
              <input
                type="checkbox"
                id="fee"
                name="fee"
                checked={data.fee}
                onChange={dataInputHandler}
                className="h-[24px] w-[24px] accent-primary"
              />
              <label
                for="fee"
                className="ml-1 inline-block text-center font-spartan text-sm"
              >
                Add 5% to help cover our costs?
              </label>
            </>
          )} */}
        </div>

        <div className="mb-7 font-bold" ref={frameRef}>
          <CardElement
            options={CARD_OPTIONS}
            onReady={() => setIsStripeIframeLoaded(true)}
            onChange={e => setStripeInputError(e.error)}
          />
          {stripeInputError && (
            <PaymentError>{stripeInputError.message}</PaymentError>
          )}
        </div>
        <div className="flex items-center justify-end">
          <DBButton
            title={
              !stripe || !elements || !isStripeIframeLoaded
                ? "Initializing Stripe, Please Wait..."
                : processing
                  ? "Processing"
                  : "Pay and donate"
            }
            className="!w-full justify-center !rounded-none"
            disableSvg
            disabled={!stripe || !elements || !isStripeIframeLoaded}
            type="submit"
          />
        </div>
      </DonationBox>
    </form>
  )
}

export default PaymentForm
