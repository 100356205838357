import React from "react"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

const MasonryLayoutWrapper = ({
  children,
  columnsCountBreakPoints,
  gutter,
}) => {
  return (
    <ResponsiveMasonry
      columnsCountBreakPoints={
        columnsCountBreakPoints
          ? columnsCountBreakPoints
          : { 350: 1, 750: 2, 900: 3 }
      }
    >
      <Masonry gutter={gutter ? gutter : "20px"}>{children}</Masonry>
    </ResponsiveMasonry>
  )
}

export default MasonryLayoutWrapper
