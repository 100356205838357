import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

// import MasonryLayoutWrapper from "../MasonryLayoutWrapper"
import YoutubeModal from "../YoutubeModal"

function CharityVideos({ videos }) {
  const [openVideoModal, setOpenVideoModal] = useState({
    status: false,
    videoId: "",
  })

  const videoClickHandler = videoId => {
    setOpenVideoModal({ status: true, videoId: videoId })
  }

  return (
    <>
      <div className="mt-4">
        <h2 className="mb-6 font-spartan font-bold">Charity stories</h2>
        {/* <MasonryLayoutWrapper
          columnsCountBreakPoints={{
            350: 1,
            760: 3,
          }}
          gutter="20px"
        > */}
        <div className="grid gap-4 md:grid-cols-3">
          {React.Children.toArray(
            videos.map(video => (
              <VideoCard
                imageThumbnail={video?.videoThumbnail?.gatsbyImageData}
                charityName={video?.charityName || "Default"}
                videoTitle={video?.videoTitle || "Default"}
                videoLink={video?.videoLink || "Default"}
                videoClickHanlder={videoClickHandler}
              />
            ))
          )}
        </div>
        {/* </MasonryLayoutWrapper> */}
      </div>
      {openVideoModal.status && (
        <YoutubeModal
          isOpen={openVideoModal.status}
          setOpen={setOpenVideoModal}
          videoId={openVideoModal.videoId}
          autoplay
        />
      )}
    </>
  )
}

export default CharityVideos

function VideoCard({
  imageThumbnail,
  charityName,
  videoTitle,
  videoLink,
  videoClickHanlder,
}) {
  return (
    <div className="rounded-2xl bg-white p-4 font-spartan shadow-donation-box backdrop-blur-2xl">
      <buton
        type="button"
        onClick={() => videoClickHanlder(videoLink)}
        className="bg-gray-02 relative mb-4 block h-[100px] cursor-pointer overflow-hidden rounded-2xl"
      >
        <GatsbyImage
          image={imageThumbnail}
          alt={videoTitle || "Toucan Charity Video"}
          className="h-full w-full"
        />
        <span className="absolute inset-0 z-10 bg-black/40" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          fill="none"
          className="absolute top-1/2 left-1/2 z-20 -translate-x-1/2 -translate-y-1/2"
        >
          <path
            d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
            stroke="#fff"
            strokeWidth="3.975"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m20 16 12 8-12 8V16Z"
            stroke="#fff"
            strokeWidth="3.975"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </buton>
      <h2 className="mb-2 text-xs uppercase tracking-[0.5em] text-gray-4">
        {charityName}
      </h2>
      <h3 className="text-sm font-bold">{videoTitle}</h3>
    </div>
  )
}
