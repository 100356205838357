import React from "react"

import {CharityBox} from "../UI"

const ImpactFundCharitiesBox = ({datoCmsImpactFund}) => {
    return (
        <div className="rounded-2xl bg-dontaion-box px-6 pt-8 pb-2 shadow-donation-box backdrop-blur-2xl">
            <div className="mb-14 md:px-6 lg:mx-auto lg:max-w-md">
                <h2 className="mb-2 text-base font-bold text-gray-1">The Charities</h2>
                <div className="charity-container mb-6 grid grid-cols-3 gap-y-4 md:grid-cols-4 lg:grid-cols-3">
                    {React.Children.toArray(
                        datoCmsImpactFund.charities.map(data => <CharityBox data={data}/>)
                    )}
                </div>
                <p className="text-sm text-gray-2 hidden">
                    Want to learn more about these charities? <br/>
                    Download{" "}
                    <a
                        className="text-primary"
                        href={
                            datoCmsImpactFund.downloadLinkUnderCharities ||
                            "https://toucanapp.onelink.me/1PM6/6e9f0fd3"
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Toucan for iOS and Android
                    </a>
                </p>
            </div>
        </div>
    )
}

export default ImpactFundCharitiesBox
