import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"
import { isParagraph } from "datocms-structured-text-utils"

import { LayoutContainer } from "../UI"
import ImpactFundDidYouKnow from "../ImpactFundDidYouKnow"
import CharityVideos from "../CharityVideos/CharityVideos"

const ImpactFundAboutFund = ({ datoCmsImpactFund, className }) => {
  const [showMore, setShowMore] = useState(true)
  return (
    <div className={`${className}`}>
      <LayoutContainer>
        <div className="mb-14">
          <h2 className="mb-4 text-base font-bold text-gray-1">
            About this fund
          </h2>
          <div className="mb-4 flex max-w-[327px] items-center border-t-[0.5px] border-b-[0.5px] py-3">
            <div className="mr-4 overflow-hidden rounded-full">
              <GatsbyImage
                image={datoCmsImpactFund.partner.avatar.gatsbyImageData}
                alt={datoCmsImpactFund.partner.avatar.alt}
                placeholder="blurred"
              />
            </div>
            <p className="text-[11px] text-gray-3">
              A fund by{" "}
              <strong className="text-[13px] text-gray-1">
                {datoCmsImpactFund.partner.name}
              </strong>
              <br />
              <span>Created {datoCmsImpactFund.meta.createdAt}</span>
            </p>
          </div>
          <div
            className={`relative mb-4 overflow-hidden ${
              showMore && "h-[145px] md:h-auto"
            }`}
          >
            {showMore && (
              <span className="absolute bottom-0 left-0 right-0 h-10 bg-gradient-to-b from-transparent to-white md:hidden" />
            )}
            <div className="max-w-[620px] text-sm leading-6">
              <StructuredText
                data={datoCmsImpactFund.description}
                customRules={[
                  renderRule(isParagraph, ({ children, key }) => {
                    if (children[0].length === 0) return <br />
                    return <p key={key}>{children}</p>
                  }),
                ]}
              />
            </div>
          </div>
          <button
            className="mx-auto block text-sm font-bold text-gray-2 md:hidden"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "Read more" : "Read less"}
          </button>
        </div>
      </LayoutContainer>
      {datoCmsImpactFund?.didyouknow?.length !== 0 && (
        <ImpactFundDidYouKnow
          didYouKnowCards={datoCmsImpactFund.didyouknow}
          didYouKnowText={datoCmsImpactFund.didyouknowtext}
        />
      )}
      {datoCmsImpactFund.charityVideo.length !== 0 && (
        <LayoutContainer>
          <CharityVideos videos={datoCmsImpactFund.charityVideo} />
        </LayoutContainer>
      )}
    </div>
  )
}

export default ImpactFundAboutFund
