import React from "react"

import { DonationBox, AmountBox, InputField, DBButton } from "../UI"

const EnterAmountModal = ({
  data,
  dataInputHandler,
  manualPrice,
  manualPriceInputHandler,
  nextStepHandler,
}) => {
  return (
    <DonationBox className="lg:mx-auto lg:max-w-md">
      <h2 className="mb-6 text-center font-spartan font-bold text-gray-1">
        Make a one-off donation
      </h2>
      <div className="mb-8 flex justify-center">
        <AmountBox
          price={5}
          className="mr-3 md:mr-7"
          onChange={dataInputHandler}
          value={data.donation}
          manualPrice={manualPrice}
        />
        <AmountBox
          price={10}
          className="mr-3 md:mr-7"
          onChange={dataInputHandler}
          value={data.donation}
          manualPrice={manualPrice}
        />
        <AmountBox
          price={25}
          className="mr-3 md:mr-7"
          onChange={dataInputHandler}
          value={data.donation}
          manualPrice={manualPrice}
        />
        <AmountBox
          price={50}
          onChange={dataInputHandler}
          value={data.donation}
          manualPrice={manualPrice}
        />
      </div>
      <div className="flex justify-center font-bold">
        <span className="mr-6 mt-3 inline-block text-gray-400">or</span>
        <InputField
          type="number"
          name="price"
          required
          placeholder="Type an amount"
          value={manualPrice}
          onChange={manualPriceInputHandler}
          className="w-full"
        />
      </div>
      {data.donation && data.donation > 0 ? (
        <DBButton
          title="Next"
          className="ml-auto mt-7"
          onClick={nextStepHandler}
        />
      ) : null}
    </DonationBox>
  )
}

export default EnterAmountModal
