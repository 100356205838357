import React, { forwardRef, useImperativeHandle } from "react"
import { useCountUp } from "react-countup"

const DonationTotal = forwardRef((props, ref) => {
  const { raisedSoFar, total } = props
  const width = (raisedSoFar / total) * 100

  const totalRef = React.useRef(null)
  const raisedSoFarRef = React.useRef(null)

  const { start: startTotal } = useCountUp({
    ref: totalRef,
    start: 0,
    end: total,
    delay: 0,
    duration: 1.2,
    separator: ",",
    decimal: ".",
    prefix: "£",
  })

  const { start: startRaised, update } = useCountUp({
    ref: raisedSoFarRef,
    start: 0,
    end: raisedSoFar,
    delay: 0,
    duration: 1.2,
    separator: ",",
    decimal: ".",
    prefix: "£",
  })

  React.useEffect(() => {
    startTotal()
  }, [total])

  React.useEffect(() => {
    startRaised()
  }, [raisedSoFar])
  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    updateRaisedValue(number) {
      update(number)
    },
  }))

  return (
    <div className="mb-14 font-spartan md:mb-10 md:px-6 lg:mx-auto lg:max-w-md">
      <h2 className="mb-2 font-bold text-gray-1">Target</h2>
      <div className="progress-bar-container">
        <div
          className="progress-bar-indicator"
          style={{ width: `${width || 0}%` }}
        />
      </div>
      <div className="mb-2 flex items-center justify-between text-sm text-gray-1">
        <h3>Target amount</h3>
        <p className="font-bold" ref={totalRef} />
      </div>
      <div className="flex items-center justify-between text-sm text-gray-1">
        <h3>Raised so far</h3>
        <p className="font-bold text-primary" ref={raisedSoFarRef} />
      </div>
    </div>
  )
})

export default DonationTotal
