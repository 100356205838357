import React from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

import EnterAmountModal from "../EnterAmountModal"
import AddGiftModal from "../AddGiftModal/AddGiftModal"
import ImpactFundDetailsBox from "../ImpactFundDetailsBox"
import PaymentForm from "../PaymentForm"

const ImpactFundDonationBox = ({
  step,
  data,
  dataInputHandler,
  giftAidHandler,
  manualPrice,
  manualPriceInputHandler,
  nextStepHandler,
  previousStepHandler,
  resetForm,
  stripeCheckoutHandler,
  datoCmsImpactFund,
  getSingleImpactFund,
  raisedSoFar,
  className,
}) => {
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUB_KEY)
  return (
    <div className={className}>
      {step === 1 ? (
        <EnterAmountModal
          data={data}
          dataInputHandler={dataInputHandler}
          manualPrice={manualPrice}
          manualPriceInputHandler={manualPriceInputHandler}
          nextStepHandler={nextStepHandler}
        />
      ) : step === 2 ? (
        <ImpactFundDetailsBox
          data={data}
          dataInputHandler={dataInputHandler}
          nextStepHandler={nextStepHandler}
          previousStepHandler={previousStepHandler}
        />
      ) : step === 3 ? (
        <AddGiftModal
          data={data}
          body={datoCmsImpactFund.giftAidText}
          dataInputHandler={dataInputHandler}
          giftAidHandler={giftAidHandler}
          nextStepHandler={nextStepHandler}
          previousStepHandler={previousStepHandler}
        />
      ) : step === 4 ? (
        <Elements stripe={stripePromise}>
          <PaymentForm
            data={data}
            dataInputHandler={dataInputHandler}
            nextStepHandler={stripeCheckoutHandler}
            previousStepHandler={previousStepHandler}
            fundName={datoCmsImpactFund.name}
            datoCmsImpactFund={datoCmsImpactFund}
            resetForm={resetForm}
            getSingleImpactFund={getSingleImpactFund}
            raisedSoFar={raisedSoFar}
          />
        </Elements>
      ) : null}
    </div>
  )
}

export default ImpactFundDonationBox
