import React, { useState } from "react"
import { renderRule, StructuredText } from "react-datocms"
import { isParagraph } from "datocms-structured-text-utils"

import { DonationBox, InputField, DBButton } from "../UI"

const AddGiftModal = ({
  data,
  dataInputHandler,
  giftAidHandler,
  nextStepHandler,
  previousStepHandler,
  body,
}) => {
  const [firstNameError, setFirstNameError] = useState("")
  // const [lastNameError, setLastNameError] = useState("")
  const [addressError, setAddressError] = useState("")
  const [postCodeError, setPostCodeError] = useState("")

  function nextStepHandlerLocal() {
    if (data.firstName === "") {
      setFirstNameError("error")
    }
    if (data.address === "") {
      setAddressError("error")
    }
    if (data.postcode === "") {
      setPostCodeError("error")
    }

    if (data.firstName === "" || data.address === "" || data.postcode === "")
      return
    giftAidHandler(true)
    return nextStepHandler()
  }

  function skipHandler() {
    giftAidHandler(false)
    return nextStepHandler()
  }

  return (
    <DonationBox className="mb-14 lg:mx-auto lg:max-w-md">
      <button onClick={previousStepHandler}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
        >
          <path
            d="M29 22H15"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M22 29L15 22L22 15"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <h2 className="mb-2 text-center font-spartan font-bold text-gray-1">
        Add Gift Aid?
      </h2>
      <div className="gift-aid-text mb-6 text-xs">
        {body.value ? (
          <StructuredText
            data={body}
            customRules={[
              renderRule(isParagraph, ({ children, key }) => {
                if (children[0].length === 0) return <br />
                return (
                  <p key={key} className="text-xs text-gray-2">
                    {children}
                  </p>
                )
              }),
            ]}
          />
        ) : (
          <p className="text-xs text-gray-2">
            If you’re a UK taxpayer, you can add 25% to your donation at no
            extra cost.{" "}
            <a
              href="https://thetoucan.app/terms-and-conditions/"
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              Learn more
            </a>
          </p>
        )}
      </div>
      <div className="mb-7 font-bold">
        <InputField
          type="text"
          name="firstName"
          placeholder="First Name"
          value={data.firstName}
          onChange={dataInputHandler}
          className="mb-10"
          error={firstNameError === "error"}
          errorMessage="First Name is Required!"
        />
        <InputField
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={data.lastName}
          onChange={dataInputHandler}
          className="mb-10"
          error={false}
        />
        <div className="justify-between md:flex">
          <InputField
            type="text"
            name="address"
            required
            placeholder="House name or no."
            value={data.address}
            onChange={dataInputHandler}
            className="mb-10 w-full md:mr-4 md:mb-0"
            error={addressError === "error"}
            errorMessage="Address is Required!"
          />
          <InputField
            type="text"
            name="postcode"
            required
            placeholder="Postcode"
            value={data.postcode}
            onChange={dataInputHandler}
            className="w-full"
            error={postCodeError === "error"}
            errorMessage="Postcode is Required!"
          />
        </div>
      </div>
      <div className="flex items-center justify-end">
        <button className="mr-6 text-sm text-primary" onClick={skipHandler}>
          Skip Gift Aid
        </button>
        <DBButton title="Add Gift Aid" onClick={nextStepHandlerLocal} />
      </div>
    </DonationBox>
  )
}

export default AddGiftModal
