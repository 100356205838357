import React from "react"

import MasonryLayoutWrapper from "../MasonryLayoutWrapper"
import { DidYouKnowPara, LayoutContainer } from "../UI"
import { StructuredText } from "react-datocms"

const ImpactFundDidYouKnow = ({ didYouKnowCards, didYouKnowText }) => {
  return (
    <div className="did-you-know relative py-6">
      <LayoutContainer>
        <h2 className="relative z-10 mb-2 text-base font-bold text-white">
          {didYouKnowText}
        </h2>
        <MasonryLayoutWrapper
          columnsCountBreakPoints={{
            350: 1,
            760: 2,
            1366: 2,
          }}
        >
          {React.Children.toArray(
            didYouKnowCards.map(card => (
              <DidYouKnowPara className="impactFundStructuredText mb-2">
                <StructuredText data={card.didyouknowcard} />
              </DidYouKnowPara>
            ))
          )}
        </MasonryLayoutWrapper>
      </LayoutContainer>
    </div>
  )
}

export default ImpactFundDidYouKnow
