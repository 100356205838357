import React, { useState } from "react"

import { DonationBox, InputField, DBButton } from "../UI"

const DetailsModal = ({
  data,
  dataInputHandler,
  nextStepHandler,
  previousStepHandler,
}) => {
  const [emailError, setEmailError] = useState("")

  function nextStepHandlerLocal() {
    if (data.email === "") {
      setEmailError("error")
    }

    if (data.email === "") return
    return nextStepHandler()
  }

  return (
    <DonationBox className="mb-14 lg:mx-auto lg:max-w-md">
      <button onClick={previousStepHandler}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
        >
          <path
            d="M29 22H15"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M22 29L15 22L22 15"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <h2 className="mb-2 text-center font-spartan font-bold text-gray-1">
        Your details
      </h2>
      <div className="mb-6 font-bold">
        <InputField
          type="text"
          name="firstName"
          placeholder="First Name"
          value={data.firstName}
          onChange={dataInputHandler}
          className="mb-10"
          error={false}
        />
        <InputField
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={data.lastName}
          onChange={dataInputHandler}
          className="mb-10"
          error={false}
        />
        <InputField
          type="email"
          name="email"
          required
          placeholder="Email address"
          value={data.email}
          onChange={dataInputHandler}
          error={emailError === "error"}
          errorMessage="Email is Required!"
        />
      </div>
      <p className="mb-6 font-spartan text-sm text-gray-2">
        By continuing, you agree to our{" "}
        <a
          href="https://thetoucan.app/terms-and-conditions/"
          target="_blank"
          rel="noreferrer"
          className="text-primary"
        >
          T&Cs
        </a>{" "}
        and{" "}
        <a
          href="https://thetoucan.app/privacy-policy/"
          target="_blank"
          rel="noreferrer"
          className="text-primary"
        >
          Privacy Policy
        </a>
      </p>
      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          id="marketingEmails"
          name="marketingEmails"
          checked={data.marketingEmailsfee}
          onChange={dataInputHandler}
          className="h-[24px] w-[24px] accent-primary"
        />
        <p
          className="ml-1 inline-block text-center font-spartan text-[14px] leading-[24px]"
        >
          Tick here for updates and offers
        </p>
      </div>
      <div className="flex justify-end">
        <DBButton title="Next" onClick={nextStepHandlerLocal} />
      </div>
    </DonationBox>
  )
}

export default DetailsModal
